<template>
  <div>
    <Report ref="report"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      :edit-function="editItem">
    </Report>
    <EditTag :uhftag-id="selectedId" :show="show" :archived="archived"
      @hide="show = false" @create="onCreate" @save="onSave" @delete="onDelete" @restore="onRestore"></EditTag>
  </div>
</template>

<script>
  import Report from '@/components/Report'
  import EditTag from '@/components/modals/EditTag'
  import { mapGetters } from 'vuex'

  export default {
    name: 'TagList',
    props: {
      searchText: String,
      archived: String
    },
    components: { Report, EditTag },
    data () {
      return {
        pageTitle: 'UHFTag List',
        model: 'Tag',
        fields: [
          {
            key: 'tag_id',
            label: 'Tag ID',
            sortable: true
          },
          {
            key: 'status',
            formatter: (v, k, item) => item.animal == null ? 'Not attached' : 'Attached',
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'attached',
            label: 'Date Attached',
            formatter: value => this.dateformat(value),
            sortable: true
          },
          {
            key: 'lost',
            // label: 'Date Lost',
            formatter: value => this.dateformat(value),
            sortable: true
          },
          {
            key: 'custom_data',
            label: 'Notes',
            sortable: true
          }
        ],
        show: false,
        selectedId: null,
        selectedIndex: null
      }
    },
    mounted () {
      document.title = `${this.pageTitle} | PBFC`
    },
    watch: {
      archived: function () {
        this.$refs.report.populateTable()
      }
    },
    methods: {
      ...mapGetters(['isStaff', 'isSupervisor', 'isAdmin']),
      /**
       * Fetches all UHF Tags according to requestParams
       * @param requestParams arguments to filter UHF Tags list by
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = `/uhftags/${this.archived}`
        url += url.endsWith('/') ? '?' : '&'
        url += requestParams.join('&')
        return this.$http.get(url)
          .then(response => {
            console.log('TagList fetchData', response)
            return response.data
          })
      },
      editItem (data) {
        console.log(data)
        this.selectedId = data == null ? null : data.item.id
        this.selectedIndex = data == null ? null : data.index
        this.show = true
      },
      onCreate (item) {
        if (!this.archived)
          this.$refs.report.onCreate(item, this.model)
      },
      onSave (item) {
        if (!this.archived)
          this.$refs.report.onSave({item: item, index: this.selectedIndex}, this.model)
      },
      onDelete () {
        if (!this.archived)
          this.$refs.report.onDelete(this.selectedIndex, this.model)
      },
      onRestore () {
        if (this.archived)
          this.$refs.report.onRestore(this.selectedIndex, this.model)
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
