import { render, staticRenderFns } from "./EditFunder.vue?vue&type=template&id=51f0416f&scoped=true&"
import script from "./EditFunder.vue?vue&type=script&lang=js&"
export * from "./EditFunder.vue?vue&type=script&lang=js&"
import style0 from "./EditFunder.vue?vue&type=style&index=0&id=51f0416f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f0416f",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BFormInvalidFeedback, BTd, BTr, BFormSelect, BForm, BCol, BRow, BButton, BModal} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BFormInvalidFeedback, BTd, BTr, BFormSelect, BForm, BCol, BRow, BButton, BModal})
    

export default component.exports