<template>
  <b-modal :title="pageTitle" v-model="showModal" size="lg" @hide="$emit('hide')" scrollable>
    <b-form>
      <b-row>
        <b-col>
          <!-- User form -->
          <b-form @submit.stop.prevent="saveItem">
            <b-table-simple class="edit-table" small borderless responsive>
              <b-tr>
                <b-td><label for="email" class="required">EMAIL:</label></b-td>
                <b-td>
                  <b-form-input
                          id="email"
                          name="email"
                          v-model="$v.form.email.$model"
                          :state="validateState('email')"
                          aria-describedby="email-live-feedback"/>
                  <b-form-invalid-feedback
                          id="email-live-feedback"
                  >This is a required field and must be a valid email address</b-form-invalid-feedback>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td><label for="first_name" class="required">FIRST NAME:</label></b-td>
                <b-td>
                  <b-form-input
                          id="first_name"
                          name="first_name"
                          v-model="$v.form.first_name.$model"
                          :state="validateState('first_name')"
                          aria-describedby="first_name-live-feedback"/>
                  <b-form-invalid-feedback
                          id="first_name-live-feedback"
                  >This field cannot be more than {{$v.form.first_name.$params.maxLength.max}} characters</b-form-invalid-feedback>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td><label for="last_name" class="required">LAST NAME:</label></b-td>
                <b-td>
                  <b-form-input
                          id="last_name"
                          name="last_name"
                          v-model="$v.form.last_name.$model"
                          :state="validateState('last_name')"
                          aria-describedby="last_name-live-feedback"/>
                  <b-form-invalid-feedback
                          id="last_name-live-feedback"
                  >This field cannot be more than {{$v.form.last_name.$params.maxLength.max}} characters</b-form-invalid-feedback>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td><label for="isPbfcStaff">PBFC STAFF:</label></b-td>
                <b-td colspan="2">
                  <b-form-checkbox id="isPbfcStaff" name="isPbfcStaff"
                    v-if="isSupervisor" v-model="form.usersetting.pbfc_staff"
                    @input="(checked) => { if (checked) this.form.usersetting.memberorg = null }" />
                  <span v-else>{{ form.usersetting.pbfc_staff ? 'Yes' : 'No' }}</span>
                  <b-form-invalid-feedback
                          id="role-live-feedback"
                  >This is a required field</b-form-invalid-feedback>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td><label for="memberorg" :class="form.usersetting.pbfc_staff ? '' : 'required'">MEMBER:</label></b-td>
                <b-td>
                  <b-form-select
                          id="memberorg"
                          name="memberorg"
                          v-model="$v.form.usersetting.memberorg.$model"
                          :state="validateState('usersetting.memberorg')"
                          :options="memberOptions"
                          :disabled="form.usersetting.pbfc_staff"
                          aria-describedby="memberorg-live-feedback"/>
                  <b-form-invalid-feedback
                          id="memberorg-live-feedback"
                  >This is a required field</b-form-invalid-feedback>
                </b-td>
              </b-tr>
              <b-tr v-if="form.usersetting.memberorg != null && form.usersetting.memberorg != ''">
                <b-td><label for="locations">LOCATIONS:</label></b-td>
                <b-td>
                  <div>(SHIFT + L Click to select multiple)</div>
                  <b-form-select
                      id="locations"
                      name="locations"
                      v-model="form.usersetting.locations"
                      :state="validateState('usersetting.location')"
                      :options="locationOptions"
                      :disabled="form.usersetting.pbfc_staff"
                      aria-describedby="locations-live-feedback" multiple/>
                  <b-form-invalid-feedback
                      id="locations-live-feedback"
                  >This is a required field</b-form-invalid-feedback>
                  <b-table :items="form.usersetting.locations" bordered striped small responsive ref="table" :fields="locationFields"
                           v-bind="$attrs" sort-direction="desc"></b-table>
                </b-td>
                <b-td>

                </b-td>
              </b-tr>
              <b-tr>
                <b-td><label for="role" class="required">ROLE:</label></b-td>
                <b-td>
                  <b-form-select
                          id="role"
                          name="role"
                          v-if="isSupervisor"
                          v-model="$v.form.usersetting.role.$model"
                          :state="validateState('usersetting.role')"
                          aria-describedby="role-live-feedback">
                    <b-form-select-option :value="rolesCpy.MEMBER">Member</b-form-select-option>
                    <b-form-select-option v-if="isSupervisor" :value="rolesCpy.STAFF">Staff</b-form-select-option>
                    <b-form-select-option v-if="isSupervisor" :value="rolesCpy.SUPERVISOR">Supervisor</b-form-select-option>
                    <b-form-select-option v-if="isAdmin" :value="rolesCpy.SYSADMIN">System Admin</b-form-select-option>
                    <b-form-select-option v-if="isAdmin" :value="rolesCpy.SCAN_EXPORT">Tag Data Export</b-form-select-option>
                  </b-form-select>
                  <span v-else>{{ roleStr(form.usersetting.role) }}</span>
                  <b-form-invalid-feedback
                          id="role-live-feedback"
                  >This is a required field</b-form-invalid-feedback>
                </b-td>
              </b-tr>
            </b-table-simple>
            <input type="submit" class="d-none"/>
          </b-form>
        </b-col>
      </b-row>
    </b-form>
    <template v-slot:modal-footer>
      <b-button class="mr-1" variant="danger" v-if="!isNew && archived.length == 0 && isSupervisor" @click="deleteItem">DELETE</b-button>
      <b-button class="mr-1" variant="success" v-if="!isNew && archived.length > 0" @click="restoreItem">RESTORE</b-button>
      <b-button class="mr-1" variant="secondary" @click="showModal = false">CANCEL</b-button>
      <b-button variant="primary" @click="saveItem">{{ isNew ? 'SAVE' : 'UPDATE' }}</b-button>
    </template>
  </b-modal>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { roles, roleStr } from '@/variables'
  import { required, requiredUnless, email, maxLength } from 'vuelidate/lib/validators'

  export default {
    name: 'EditUser',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      userId: Number,
      archived: {
        type: String,
        default: () => ''
      }
    },
    data () {
      return {
        rolesCpy: roles,
        form: {
          // User attributes
          email: '',
          first_name: '',
          last_name: '',
          usersetting: {
            memberorg: null,
            role: roles.MEMBER,  // default value
            pbfc_staff: false,
            locations: []
          }
        },
        initialEmail: '',
        memberOptions: [],  // [{ value: null, text: 'None' }],
        locationOptions: [],
        locationFields: [
          {
            key: 'name',
            label: 'Selected Locations',
            sortable: true
          },
        ],
        showModal: false
      }
    },
    /**
     * This is used for validating the form fields
     */
    validations: {
      form: {
        email: {
          required,
          email,
        },
        first_name: {
          required,
          maxLength:maxLength(30)
        },
        last_name: {
          required,
          maxLength: maxLength(30)
        },
        usersetting: {
          memberorg: {
            required: requiredUnless(function () {
              return this.form.usersetting.pbfc_staff || this.form.usersetting.role == 1
            }),
          },
          locations: {
            required: requiredUnless(function () {
              return this.form.usersetting.pbfc_staff || this.form.usersetting.role == 1
            })
          },
          role:{
            required
          }
        }
      }
    },
    mounted () {
      this.fetchMembers()
    },
    watch: {
      show: function (newVal) {
        this.form = {
          email: null,
          first_name: null,
          last_name: null,
          usersetting: {
            memberorg: null,
            role: roles.MEMBER,
            pbfc_staff: false,
            locations: []
          }
        }
        this.initialEmail = null
        this.$v.$reset()
        if (newVal) this.fetchData()
        this.showModal = newVal
      },
      memberorg: function () {
        if (this.form.usersetting.memberorg != null && this.form.usersetting.memberorg != '')
        {
          this.fetchLocations()
        }
      }
    },
    computed: {
      ...mapGetters(['isSupervisor', 'isAdmin']),
      isNew: function () {
        return this.userId == null
      },
      pageTitle: function () {
        return this.isNew ? 'New Account' : 'Update Account'
      },
      memberorg: function () {
        return this.form.usersetting.memberorg
      },
      locations: function () {
        return this.form.usersetting.locations
      }
    },
    methods: {
      validateState (name) {
        const { $dirty, $error } = name.split('.').reduce((o,i)=>o[i] ? o[i] : {}, this.$v.form)
        return $dirty ? $error ? false : null : null
      },
      /**
       * This fetches the data for the specific user to be edited.
       */
      fetchData() {
        if (!this.isNew) {
          this.$http.get(`/users/${this.userId}/${this.archived}`)
                  .then(response => {
                    console.log('EditUser fetchData', response)
                    let user = response.data
                    this.form = {
                      email: user.email,
                      first_name: user.first_name,
                      last_name: user.last_name,
                      usersetting: {
                        memberorg: user.usersetting.memberorg,
                        role: user.usersetting.role,
                        pbfc_staff: user.usersetting.pbfc_staff,
                        locations: user.usersetting.locations
                      }
                    }
                    this.initialLocations = user.usersetting.locations
                    this.initialEmail = user.email
                  })
                  .catch(e => {
                    console.log('EditUser fetchData', e, e.response)
                    this.setAlert({ variant: 'danger', message: e.message })
                  })
        }
      },
      /**
       * This fetches the data for all selectable memberorgs.
       */
      fetchMembers () {
        this.$http.get('/memberorgs/?get_all=true')
          .then(response => {
            console.log('EditUser fetchMembers', response)
            this.memberOptions = this.memberOptions.concat(response.data.map(member => ({ value: member.id, text: `${member.business_name} (${member.member_id})` })))
          })
          .catch(e => {
            console.log('EditUser fetchMembers', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      fetchLocations () {
        this.$http.get(`/locations/?memberorg=${this.form.usersetting.memberorg}`)
            .then(response => {
              console.log('EditUser fetchLocations', response)
              this.locationOptions = response.data.results.map(e => ({ value: {id: e.id, name: `${e.name}`}, text: `${e.name}` }))
              this.form.usersetting.locations = []
              for (let location of this.locationOptions) {
                if (this.initialLocations != null && this.initialLocations.includes(location.value.id)) {
                  this.form.usersetting.locations.push(location.value)
                }
              }
            })
            .catch(e => {
              console.log('EditUser fetchLocations', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
      },
      /**
       * This checks the form using vuelidate and then edits the user if the provided information is acceptable.
       */
      saveItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        if (this.form.usersetting.locations != null) {
          for (let i = 0; i < this.form.usersetting.locations.length; i++)
          {
            this.form.usersetting.locations[i] = this.form.usersetting.locations[i].id
          }
        }
        console.log(`This is the user setting location: ${this.form.usersetting.locations}`)
        this.form = this.nullBlanks(this.form)
        let request = null
        if (this.isNew) {
          request = this.$http.post('/users/', this.form)
        } else {
          request = this.$http.patch(`/users/${this.userId}/${this.archived}`, this.form)
        }
        request
                .then(response => {
                  console.log('EditUser saveItem', response)
                  // check if editing own account
                  if (this.$store.getters.user.id == response.data.id) {
                    // update sessionStorage user object
                    this.$store.commit('setUser', response.data)
                    if (response.data.email != this.initialEmail) {
                      // if email changed, need to force user logout since token won't work anymore
                      this.setAlert({ message: 'Email has been updated. Please verify your new email and set your password to login.', variant: 'success' })
                      this.$store.dispatch('logout')
                    }
                  }
                  if (response.data.email != this.initialEmail) {
                    this.setAlert({ message: 'New verification email has been sent. Account will not be able to login until new email has been verified.', variant: 'success' })
                  }
                  this.$emit(this.isNew ? 'create' : 'save', response.data)
                  this.showModal = false
                })
                .catch(e => {
                  console.log('EditUser saveItem', e, e.response)
                  this.setAlert({ variant: 'danger', message:  e.response.status == 400 ? e.response.data : e.message })
                })
      },
      /**
       * This will delete the user
       */
      deleteItem () {
        this.$http.delete(`/users/${this.userId}/`)
                .then(response => {
                  console.log('EditUser deleteItem', response)
                  this.$emit('delete', this.userId)
                  this.showModal = false
                })
                .catch(e => {
                  console.log('EditUser deleteItem', e, e.response)
                  this.setAlert({ variant: 'danger', message: e.message })
                })
      },
      /**
       * This will restore a deleted user
       */
      restoreItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        this.form.archived = false
        this.$http.patch(`/users/${this.userId}/${this.archived}`, this.form)
                .then(response => {
                  console.log('EditUser restoreItem', response)
                  this.$emit('restore')
                  this.showModal = false
                })
                .catch(e => {
                  console.log('EditUser restoreItem', e, e.response)
                })
      },
      roleStr
    }
  }
</script>

<style lang="scss" scoped>
  .edit-table {
    th, td {
      padding: 5px;
    }
  }
</style>
