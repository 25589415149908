<template>
  <div>
    <Report ref="report"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      :edit-function="editItem"
      new-button-text="NEW LENDING INSTITUTION">
    </Report>
    <EditFunder :funder-id="selectedId" :show="show" :archived="archived"
      @hide="show = false" @create="onCreate" @save="onSave" @delete="onDelete" @restore="onRestore"></EditFunder>
  </div>
</template>

<script>
  import Report from '@/components/Report'
  import EditFunder from '@/components/modals/EditFunder'

  export default {
    name: 'FunderList',
    props: {
      searchText: String,
      archived: String
    },
    components: { Report, EditFunder },
    data () {
      return {
        pageTitle: 'Lending Institutions',
        model: 'Funder',
        fields: [
          {
            key: 'funder_name',
            label: 'Lending Institution',
            sortable: true
          },
          {
            key: 'contact_name',
            label: 'Contact Name',
            sortable: true
          },
          {
            key: 'email',
            label: 'Email',
            sortable: true
          },
          {
            key: 'phone',
            label: 'Phone Number',
            sortable: true
          },
          {
            key: 'member.business_name',
            label: 'Member name',
            sortable: true
          },
        ],
        show: false,
        selectedId: null,
        selectedIndex: null
      }
    },
    mounted () {
      document.title = `${this.pageTitle}`
    },
    watch: {
      archived: function () {
        this.$refs.report.populateTable()
      }
    },
    methods: {
      fetchData (requestParams) {
        let url = `/funders/${this.archived}`
        url += url.endsWith('/') ? '?' : '&'
        url += requestParams.join('&')
        return this.$http.get(url)
          .then(response => {
            console.log('FunderList fetchData', response)
            return response.data
          })
      },
      editItem (data) {
        this.selectedId = data == null ? null : data.item.id
        this.selectedIndex = data == null ? null : data.index
        this.show = true
      },
      onCreate (item) {
        if (!this.archived)
          this.$refs.report.onCreate(item, this.model)
      },
      onSave (item) {
        if (!this.archived)
          this.$refs.report.onSave({item: item, index: this.selectedIndex}, this.model)
      },
      onDelete () {
        if (!this.archived)
          this.$refs.report.onDelete(this.selectedIndex, this.model)
      },
      onRestore () {
        if (this.archived)
          this.$refs.report.onRestore(this.selectedIndex, this.model)
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
