import { render, staticRenderFns } from "./UserList.vue?vue&type=template&id=ea457722&scoped=true&"
import script from "./UserList.vue?vue&type=script&lang=js&"
export * from "./UserList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea457722",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BIconPencilSquare, BLink, BButton} from 'bootstrap-vue'
    installComponents(component, {BIconPencilSquare, BLink, BButton})
    

export default component.exports