import { render, staticRenderFns } from "./LocationList.vue?vue&type=template&id=15ca1614&scoped=true&"
import script from "./LocationList.vue?vue&type=script&lang=js&"
export * from "./LocationList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ca1614",
  null
  
)

export default component.exports