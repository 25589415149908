import { render, staticRenderFns } from "./EditUser.vue?vue&type=template&id=485004d8&scoped=true&"
import script from "./EditUser.vue?vue&type=script&lang=js&"
export * from "./EditUser.vue?vue&type=script&lang=js&"
import style0 from "./EditUser.vue?vue&type=style&index=0&id=485004d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485004d8",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BTd, BFormInput, BFormInvalidFeedback, BTr, BFormCheckbox, BFormSelect, BTable, BFormSelectOption, BTableSimple, BForm, BCol, BRow, BButton, BModal} from 'bootstrap-vue'
    installComponents(component, {BTd, BFormInput, BFormInvalidFeedback, BTr, BFormCheckbox, BFormSelect, BTable, BFormSelectOption, BTableSimple, BForm, BCol, BRow, BButton, BModal})
    

export default component.exports