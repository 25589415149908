<template>
  <div>
    <Report ref="report"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      :edit-function="editItem">
      <template v-slot:cell(lending_institution)="data">
        <div v-if="data.item.pbfc_funded">PBFC</div><div v-else-if="data.item.funder">{{ data.item.funder.funder_name }}</div><div v-else>N/A</div>
      </template>
      <template v-slot:cell(notes)="{ value }">
        <b-icon-exclamation-square-fill v-if="value" variant="info" />
      </template>
    </Report>
    <EditLot :lot-id="selectedId" :show="show" :archived="archived"
      @hide="show = false" @create="onCreate" @save="onSave" @delete="onDelete" @restore="onRestore"></EditLot>
  </div>
</template>

<script>
  import Report from '@/components/Report'
  import EditLot from '@/components/modals/EditLot'

  export default {
    name: 'LotList',
    props: {
      searchText: String,
      archived: String
    },
    components: { Report, EditLot },
    data () {
      return {
        pageTitle: 'Lots',
        model: 'Lot',
        fields: [
          {
            key: 'identifier',
            label: 'Number',
            sortable: true
          },
          {
            key: 'location.name',
            label: 'Location',
            sortable: true
          },
          {
            key: 'memberorg',
            label: 'Member',
            formatter: value => value.map(e => e.business_name).join(', '),
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'lending_institution',
            label: 'Lending Institution',
            sortable: true
          },
          {
            key: 'notes',
            formatter: value => value != null && value != '',
            filterByFormatted: true,
            sortByFormatted: true,
            sortable: true,
            class: 'skinny-col',
            tdClass: 'text-center'
          }
        ],
        show: false,
        selectedId: null,
        selectedIndex: null
      }
    },
    mounted () {
      document.title = `${this.pageTitle} | PBFC`
    },
    watch: {
      archived: function () {
        this.$refs.report.populateTable()
      }
    },
    methods: {
      /**
       * Fetches all lots according to requestParams
       * @param requestParams arguments to filter lots list by
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = `/lots/${this.archived}`
        url += url.endsWith('/') ? '?' : '&'
        url += requestParams.join('&')
        return this.$http.get(url)
          .then(response => {
            console.log('LotList fetchData', response)
            return response.data
          })
      },
      editItem (data) {
        this.selectedId = data == null ? null : data.item.id
        this.selectedIndex = data == null ? null : data.index
        this.show = true
      },
      onCreate (item) {
        if (!this.archived)
          this.$refs.report.onCreate(item, this.model)
      },
      onSave (item) {
        if (!this.archived)
          this.$refs.report.onSave({item: item, index: this.selectedIndex}, this.model)
      },
      onDelete () {
        if (!this.archived)
          this.$refs.report.onDelete(this.selectedIndex, this.model)
      },
      onRestore () {
        if (this.archived)
          this.$refs.report.onRestore(this.selectedIndex, this.model)
      }
    }
  }
</script>

<style lang="scss" scoped>
/deep/ .skinny-col {
  max-width: 0.5rem;
}
</style>
