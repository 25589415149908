<template>
  <b-modal :title="pageTitle" v-model="showModal" @hide="$emit('hide')" scrollable>
    <b-form @submit.stop.prevent="saveItem">
      <b-row>
        <b-col>
          <table class="edit-table">
            <tr>
              <td><label for="identifier" class="required">LOT NUMBER:</label></td>
              <td>
                <b-form-input
                        id="identifier"
                        name="identifier"
                        v-mask="'*{0,20}'"
                        v-model="$v.form.identifier.$model"
                        :state="validateState('identifier')"
                        aria-describedby="identifier-live-feedback"/>
                <b-form-invalid-feedback
                        id="identifier-live-feedback"
                >This is a required field and cannot be more than {{$v.form.identifier.$params.maxLength.max}} letters</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="memberorg" class="required">MEMBER:</label></td>
              <td>
                <b-form-select
                    id="memberorg"
                    name="memberorg"
                    multiple
                    v-model="form.memberorg"
                    :options="memberOptions"
                    :state="validateState('memberorg')"
                    aria-describedby="memberorg-live-feedback"/>
                <b-form-invalid-feedback
                    id="memberorg-live-feedback"
                >This is a required field</b-form-invalid-feedback>
              </td>
            </tr>
            <tr v-if="!pbfcFunded">
              <td><label for="funder" class="required">LENDING INSTITUTION:</label></td>
              <td>
                <b-form-select
                    id="funder"
                    name="funder"
                    v-model="$v.form.funder.$model"
                    :options="funderOptions"
                    :state="validateState('funder')"/>
              </td>
            </tr>
            <tr>
              <td><label for="notes">NOTES:</label></td>
              <td>
                <b-form-textarea id="notes"
                  v-model="form.notes"
                  placeholder="Lot notes..."
                  rows="2" max-rows="6" />
              </td>
            </tr>
          </table>
          <input type="submit" class="d-none"/>
        </b-col>
      </b-row>
    </b-form>
    <template v-slot:modal-footer>
      <div>
        <b-button class="mr-1" variant="danger" v-if="!isNew && archived.length == 0 && $store.getters.isSupervisor" @click="deleteItem">DELETE</b-button>
        <b-button class="mr-1" variant="success" v-if="!isNew && archived.length > 0" @click="restoreItem">RESTORE</b-button>
        <b-button class="mr-1" variant="secondary" @click="showModal = false">CANCEL</b-button>
        <b-button variant="primary" @click="saveItem">{{ isNew ? 'SAVE' : 'UPDATE' }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import {maxLength, required} from 'vuelidate/lib/validators'

  export default {
    name: 'EditLot',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      lotId: Number,
      archived: {
        type: String,
        default: () => ''
      }
    },
    data () {
      return {
        showModal: false,
        memberOptions: [],
        funderOptions: [],
        pbfcFunded: true,
        form: {
          identifier: null,
          memberorg: [],
          funder: null,
          notes: null
        },
      }
    },
    mounted () {
      this.fetchMembers()
    },
    /**
     * This is used for validating the form fields
     */
    validations: {
      form: {
        identifier: {
          required,
          maxLength: maxLength(20)
        },
        memberorg: {
          required
        },
        funder: {

        }
      }
    },
    watch: {
      show: function (newVal) {
        this.form = {
          identifier: null,
          memberorg: null,
          funder: null,
          notes: null
        }
        this.$v.$reset()
        if (newVal) this.fetchData()
        this.showModal = newVal
      },
      member: function (newVal) {
        if (newVal) {
          this.form.funder = null
          this.fetchFunders()
        }
      }
    },
    computed: {
      isNew: function () {
        return this.lotId == null
      },
      pageTitle: function () {
        return this.isNew ? 'New Lot' : 'Update Lot'
      },
      member: function () {
        return this.form.memberorg
      }
    },
    methods: {
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? $error ? false : null : null
      },
      /**
       * This fetches the data for the specific lot to be edited.
       */
      fetchData () {
        if (!this.isNew) {
          this.$http.get(`/lots/${this.lotId}/${this.archived}`)
            .then(response => {
              console.log('EditLot fetchData', response)
              let lot = response.data
              this.form = {
                identifier: lot.identifier,
                memberorg: this.highlightMembers(response.data),
                funder: lot.funder != null ? lot.funder.id : null,
                notes: lot.notes
              }
              this.pbfcFunded = response.data.pbfc_funded
            })
            .catch(e => {
              console.log('EditLot fetchData', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
        }
      },
      highlightMembers(data){
        return data.memberorg.map((element) => (element.id))
      },
      /**
       * This fetches the data for all selectable memberorgs.
       */
      fetchMembers () {
        this.$http.get(`/memberorgs/?get_all=true`)
          .then(response => {
            console.log('EditLot fetchMembers', response)
            this.memberOptions = response.data.map(e => ({ value: e.id, text: `${e.business_name} (${e.member_id})` }))
          })
          .catch(e => {
            console.log('EditLot fetchMembers', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This fetches the data for all selectable funders.
       */
      fetchFunders () {
        //for loop for each item in memberorg
        for(let member of this.form.memberorg){
          this.$http.get(`/funders/?member=${member}`)
              .then(response => {
                console.log('EditLot fetchFunders', response)
                if (response.data != null)
                {
                  this.funderOptions = response.data.results.map(e => ({ value: e.id, text: `${e.funder_name}` }))
                } else {
                  this.funderOptions = []
                }
              })
              .catch(e => {
                console.log('EditLot fetchFunders', e, e.response)
                this.setAlert({ variant: 'danger', message: e.message })
              })
        }
        // if(this.form.memberorg.length > 1){
        //   console.log("greater than one memberorg")
        // } else {
        //
        // }
      },
      /**
       * This checks the form using vuelidate and then edits the lot if the provided information is acceptable.
       */
      saveItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        let request = null
        if (this.isNew) {
          // Post to create
          request = this.$http.post('/lots/', this.form)
        } else {
          // Put to update
          request = this.$http.patch(`/lots/${this.lotId}/${this.archived}`, this.form)
        }
        request
          .then(response => {
            console.log('EditLot saveItem', response)
            this.$emit(this.isNew ? 'create' : 'save', response.data)
            this.showModal = false
          })
          .catch(e => {
            console.log('EditLot saveItem', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This will delete the lot
       */
      deleteItem () {
        this.$http.delete(`/lots/${this.lotId}/`)
          .then(() => {
            this.$emit('delete')
            this.showModal = false
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This will restore a deleted lot
       */
      restoreItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        this.form.archived = false
        this.$http.patch(`/lots/${this.lotId}/${this.archived}`, this.form)
          .then(response => {
            console.log('EditLot restoreItem', response)
            this.$emit('restore')
            this.showModal = false
          })
          .catch(e => {
            console.log('EditLot restoreItem', e, e.response)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
td {
  padding: 5px;
}
</style>
