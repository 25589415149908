import { render, staticRenderFns } from "./EditLot.vue?vue&type=template&id=39b5b4ac&scoped=true&"
import script from "./EditLot.vue?vue&type=script&lang=js&"
export * from "./EditLot.vue?vue&type=script&lang=js&"
import style0 from "./EditLot.vue?vue&type=style&index=0&id=39b5b4ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39b5b4ac",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BFormInvalidFeedback, BFormSelect, BFormTextarea, BCol, BRow, BForm, BButton, BModal} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BFormInvalidFeedback, BFormSelect, BFormTextarea, BCol, BRow, BForm, BButton, BModal})
    

export default component.exports