<template>
  <div id="admin">
    <b-row class="p-0 m-0 h-100 w-100">
      <b-col sm="3" xl="2" :class="sidepanelClass">
        <b-row class="panel-section-1">
          <!-- Search input -->
          <b-col>
            <b-form-group label="SEARCH" label-for="search-input" class="has-feedback">
              <!-- <b-icon-search id="search-icon"/> -->
              <b-form-input id="search-input" ref="searchInput" debounce="500" v-model="searchText" type="text" class="form-control"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="panel-section-2">
          <!-- Links for members, staff -->
          <b-col>
            <label>NAVIGATION</label>
            <b-container class="sidepanel-nav">
              <ul class="list-unstyled">
                <li v-if="isStaff"><b-link :to="{ name: 'MemberList' }">MEMBERS</b-link></li>
                <li v-if="isStaff"><b-link :to="{ name: 'AccountList' }">ACCOUNTS</b-link></li>
                <li v-if="isSupervisor"><b-link :to="{ name: 'LocationList' }">LOCATIONS</b-link></li>
                <li v-if="isSupervisor"><b-link :to="{ name: 'GateList' }">GATES</b-link></li>
                <li class="mt-2"><b-link :to="{ name: 'LotList' }">LOTS</b-link></li>
                <li v-if="isMember || isAdmin"><b-link :to="{ name: 'FunderList' }">LENDING INSTITUTIONS</b-link></li>
                <li v-if="isAdmin"><b-link :to="{ name: 'AnimalList' }">ANIMALS</b-link></li>
                <li v-if="isSupervisor"><b-link :to="{ name: 'UHFTagList' }">UHF TAGS</b-link></li>
                <br/>
                <li v-if="isSupervisor"><b-link :to="{ name: 'AppSettings' }">App Settings</b-link></li>
              </ul>
            </b-container>
            <b-form-checkbox switch v-if="isSupervisor" v-model="showArchived" @click="toggleSidepanel">Archived</b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="9" xl="10" class="h-100 p-0">
        <a :class="sidepanelBtnClass" @click="toggleSidepanel">
          <span class="box">
            <span class="inner"></span>
          </span>
        </a>
        <router-view :searchText="searchText" :archived="showArchived ? '?archived=1' : ''" ref="view" style="height: inherit;"></router-view>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { sidepanelMixin } from '@/mixins'

  export default {
    name: 'Admin',
    mixins: [sidepanelMixin],
    data () {
      return {
        searchText: '',
        showArchived: false
      }
    },
    computed: {
      isSupervisor: function () {
        return this.$store.getters.isSupervisor
      },
      isStaff: function () {
        return this.$store.getters.isStaff
      },
      isMember: function () {
        return this.$store.getters.isMember
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin
      }
    }
  }
</script>
