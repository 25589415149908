<template>
  <b-modal :title="pageTitle" v-model="showModal" @hide="$emit('hide')" scrollable>
    <b-form>
      <b-row>
        <b-col>
          <b-form @submit.stop.prevent="saveItem">
            <table class="edit-table">
              <tr>
                <td><label for="tag_id">TAG ID:</label></td>
                <td>
                  <b-form-input
                          id="tag_id"
                          name="tag_id"
                          v-model="$v.form.tag_id.$model"
                          :state="validateState('tag_id')"
                          aria-describedby="tag_id-live-feedback"/>
                  <b-form-invalid-feedback
                          id="tag_id-live-feedback"
                  >This field cannot be more than {{$v.form.tag_id.$params.maxLength.max}} characters.</b-form-invalid-feedback>
                </td>
              </tr>
              <tr>
                <td><label for="lost">LOST:</label></td><td><b-form-checkbox id="lost" v-model="form.lost"/></td>
              </tr>
              <tr>
                <td><label for="custom_data">CUSTOM DATA:</label></td>
                <td colspan="3">
                  <b-form-textarea
                          id="custom_data"
                          name="custom_data"
                          rows="5"
                          cols="40"
                          style="resize: none;"
                          v-model="$v.form.custom_data.$model"
                          :state="validateState('custom_data')"
                          aria-describedby="custom_data-live-feedback"/>
                  <b-form-invalid-feedback
                          id="custom_data-live-feedback"
                  >This field cannot be more than {{$v.form.custom_data.$params.maxLength.max}} characters.</b-form-invalid-feedback>
                </td>
              </tr>
            </table>
            <input type="submit" class="d-none"/>
          </b-form>
        </b-col>
      </b-row>
    </b-form>
    <template v-slot:modal-footer>
      <div>
        <b-button class="mr-1" variant="danger" v-if="!isNew && archived.length == 0" @click="deleteItem">DELETE</b-button>
        <b-button class="mr-1" variant="success" v-if="!isNew && archived.length > 0" @click="restoreItem">RESTORE</b-button>
        <b-button class="mr-1" variant="secondary" @click="showModal = false">CANCEL</b-button>
        <b-button variant="primary" @click="saveItem">{{ isNew ? 'SAVE' : 'UPDATE' }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import {required, maxLength} from 'vuelidate/lib/validators'

  export default {
    name: 'EditTag',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      uhftagId: Number,
      archived: {
        type: String,
        default: () => ''
      }
    },
    data () {
      return {
        showModal: false,
        form: {
          // uhftag attributes
          tag_id: null,
          lost: null,
          custom_data: null
        },
      }
    },
    /**
     * This is used for validating the form fields
     */
    validations: {
      form: {
        tag_id: {
          required,
          maxLength: maxLength(24)
        },
        custom_data: {
          maxLength: maxLength(128)
        }
      }
    },
    watch: {
      show: function (newVal) {
        this.form = {
          tag_id: null,
          lost: null,
          custom_data: null
        }
        this.$v.$reset()
        if (newVal) this.fetchData()
        this.showModal = newVal
      }
    },
    computed: {
      isNew: function () {
        return this.uhftagId == null
      },
      pageTitle: function () {
        return this.isNew ? 'New UHFTag' : 'Update UHFTag'
      }
    },
    methods: {
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? $error ? false : null : null
      },
      /**
       * This fetches the data for the specific UHF tag to be edited.
       */
      fetchData () {
        if (!this.isNew) {
          this.$http.get(`/uhftags/${this.uhftagId}/${this.archived}`)
            .then(response => {
              console.log('EditUHFTag fetchData', response)
              let uhftag = response.data
              this.form.tag_id = uhftag.tag_id
              this.form.lost = uhftag.lost != null
              this.form.custom_data = uhftag.custom_data
            })
            .catch(e => {
              console.log('EditUHFTag fetchData', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
        }
      },
      /**
       * This checks the form using vuelidate and then edits the UHF tag if the provided information is acceptable.
       */
      saveItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        let request = null
        this.form.lost = this.form.lost ? new Date() : null
        if (this.isNew) {
          // Post to create
          request = this.$http.post('/uhftags/', this.form)
        } else {
          // Put to update
          request = this.$http.patch(`/uhftags/${this.uhftagId}/${this.archived}`, this.form)
        }
        request
          .then(response => {
            console.log('EditTag saveItem', response)
            this.$emit(this.isNew ? 'create' : 'save', response.data)
            this.showModal = false
          })
          .catch(e => {
            console.log('EditTag saveItem', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This will delete the UHF tag
       */
      deleteItem () {
        this.$http.delete(`/uhftags/${this.uhftagId}/`)
          .then(response => {
            console.log(response)
            this.$emit('delete', this.uhftagId)
            this.showModal = false
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This will restore a deleted UHF tag
       */
      restoreItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        this.form.archived = false
        this.$http.patch(`/uhftags/${this.uhftagId}/${this.archived}`, this.form)
          .then(response => {
            console.log('EditTag restoreItem', response)
            this.$emit('restore')
            this.showModal = false
          })
          .catch(e => {
            console.log('EditTag restoreItem', e, e.response)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
td {
  padding: 5px;
}
</style>
