<template>
  <div>
    <Report ref="report"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      :edit-function="editItem"
      new-button-text="NEW ACCOUNT">
      <template v-slot:cell(edit)="data">
        <div class="text-center">
          <b-link @click="editItem(data)" v-if="editable(data.item)"><b-icon-pencil-square/></b-link>
        </div>
      </template>
      <template v-slot:cell(email)="{ item, value }">
        <span v-if="!item.email_verified" class="text-split">{{ value }} <span><b-button size="sm" @click="resendVerification(item)">Resend Verification Email</b-button></span></span>
        <span v-else>{{ value }}</span>
      </template>
    </Report>
    <EditUser :user-id="selectedId" :show="show" :archived="archived"
      @hide="show = false" @create="onCreate" @save="onSave" @delete="onDelete" @restore="onRestore"></EditUser>
  </div>
</template>

<script>
  import Report from '@/components/Report'
  import EditUser from '@/components/modals/EditUser'
  import { mapGetters } from 'vuex'
  import { roleStr } from '@/variables'

  export default {
    name: 'UserList',
    props: {
      searchText: String,
      archived: String
    },
    components: { Report, EditUser },
    data () {
      return {
        pageTitle: 'Account List',
        model: 'Account',
        fields: [
          {
            key: 'first_name',
            label: 'First name',
            sortable: true
          },
          {
            key: 'last_name',
            label: 'Last name',
            sortable: true
          },
          {
            key: 'usersetting.role',
            label: 'Role',
            formatter: value => roleStr(value),
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'email',
            sortable: true
          }
        ],
        show: false,
        selectedId: null,
        selectedIndex: null
      }
    },
    mounted () {
      document.title = `${this.pageTitle} | PBFC`
    },
    watch: {
      archived: function () {
        this.$refs.report.populateTable()
      }
    },
    computed: {
      ...mapGetters(['isStaff', 'isSupervisor', 'isAdmin', 'user'])
    },
    methods: {
      /**
       * Fetches all users according to requestParams
       * @param requestParams arguments to filter user list by
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = `/users/${this.archived}`
        url += url.endsWith('/') ? '?' : '&'
        url += requestParams.join('&')
        return this.$http.get(url)
          .then(response => {
            console.log('UserList fetchData', response)
            return response.data
          })
      },
      editable (user) {
        if (this.isStaff && user.usersetting.role > this.user.usersetting.role) {
          return false
        }
        return true
      },
      editItem (data) {
        console.log('editItem', data)
        this.selectedId = data == null ? null : data.item.id
        this.selectedIndex = data == null ? null : data.index
        this.show = true
      },
      onCreate (item) {
        if (!this.archived)
          this.$refs.report.onCreate(item, this.model)
      },
      onSave (item) {
        if (!this.archived)
          this.$refs.report.onSave({item: item, index: this.selectedIndex}, this.model)
      },
      onDelete () {
        if (!this.archived)
          this.$refs.report.onDelete(this.selectedIndex, this.model)
      },
      onRestore () {
        if (this.archived)
          this.$refs.report.onRestore(this.selectedIndex, this.model)
      },
      /**
       * This re-sends the verification email
       * @param user The user to re-send the verification email to
       */
      resendVerification (user) {
        this.$http.post(`/resend-verification/${user.id}/`)
          .then(response => {
            console.log('resendVerification', response)
            this.setAlert({ message: response.data, variant: 'success' })
          })
          .catch(e => {
            console.log('resendVerification', e, e.response)
            this.setAlert({ message: `Error trying to resend verification email. ${e}`, variant: 'danger' })
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
