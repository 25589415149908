<template>
  <div>
    <Report ref="report"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      :edit-function="editItem"
      new-button-text="NEW GATE">
    </Report>
    <EditGate :gate-id="selectedId" :show="show" :archived="archived" :locations="locations"
      @hide="show = false" @create="onCreate" @save="onSave" @delete="onDelete" @restore="onRestore"></EditGate>
  </div>
</template>

<script>
  import Report from '@/components/Report'
  import EditGate from '@/components/modals/EditGate'
  import { gateTypes } from '@/variables'

  export default {
    name: 'GateList',
    props: {
      searchText: String,
      archived: String
    },
    components: { Report, EditGate },
    data () {
      return {
        pageTitle: 'Gates',
        model: 'Gate',
        gates: [],
        fields: [
          {
            key: 'gate_id',
            sortable: true
          },
          {
            key: 'gate_name',
            sortable: true
          },
          {
            key: 'gate_type',
            formatter: value => gateTypes[value],
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'location.name',
            label: 'Location',
            sortable: true
          }
        ],
        locations: [],
        show: false,
        selectedId: null,
        selectedIndex: null
      }
    },
    mounted () {
      document.title = `${this.pageTitle} | PBFC`
      this.fetchLocations()
    },
    watch: {
      archived: function () {
        this.$refs.report.populateTable()
      }
    },
    methods: {
      /**
       * Fetches all gates according to requestParams
       * @param requestParams arguments to filter gates list by
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = `/gates/${this.archived}`
        url += url.endsWith('/') ? '?' : '&'
        url += requestParams.join('&')
        return this.$http.get(url)
          .then(response => {
            console.log('GatesList fetchData', response)
            return response.data
          })
      },
      /**
       * This fetches all locations
       */
      fetchLocations () {
        this.$http.get('/locations/?get_all=true')
          .then(response => {
            console.log('GatesList fetchLocations', response)
            this.locations = response.data
          })
          .catch(e => {
            console.log('GatesList fetchLocations', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      editItem (data) {
        this.selectedId = data == null ? null : data.item.id
        this.selectedIndex = data == null ? null : data.index
        this.show = true
      },
      onCreate (item) {
        if (!this.archived)
          this.$refs.report.onCreate(item, this.model)
      },
      onSave (item) {
        if (!this.archived)
          this.$refs.report.onSave({item: item, index: this.selectedIndex}, this.model)
      },
      onDelete () {
        if (!this.archived)
          this.$refs.report.onDelete(this.selectedIndex, this.model)
      },
      onRestore () {
        if (this.archived)
          this.$refs.report.onRestore(this.selectedIndex, this.model)
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
