<template>
  <b-modal :title="pageTitle" v-model="showModal" size="lg" @hide="$emit('hide')">
    <b-form>
      <b-row>
        <b-col>
          <b-form @submit.stop.prevent="saveItem">
            <table class="edit-table">
              <tr>
                <td>
                  <label for="pen">PEN #:</label>
                </td>
                <td>
                  <b-form-input
                          id="pen"
                          name="pen"
                          v-model="$v.form.pen.$model"
                          :state="validateState('pen')"
                          aria-describedby="pen-live-feedback"/>
                  <b-form-invalid-feedback
                          id="pen-live-feedback"
                  >This is a required field and cannot be more than {{$v.form.pen.$params.maxLength.max}} numbers</b-form-invalid-feedback>

                </td>
              </tr>
              <tr>
                <td><label for="female">GENDER:</label></td>
                <td><b-form-select id="female" v-model="form.female" :options="genderOptions"/></td>
              </tr>
              <tr>
                <td><label for="inducted">INDUCTED:</label></td>
                <!-- <td><b-form-input type="datetime-local"  id="inducted" v-model="form.inducted" step="1"/></td> -->
                <td><datetime date-template="YYYY-MM-dd H:i:s" id="inducted" v-model="form.inducted"/></td>
              </tr>
              <tr>
                <td><label for="validation-status">VALIDATION STATUS:</label></td>
                <td><b-form-select id="validation-status" v-model="form.is_validated" :options="validationOptions"/></td>
              </tr>
              <tr>
                <td><label for="validated-date">DATE VALIDATED:</label></td>
                <!-- <td><b-form-input type="datetime-local" id="validated-date" v-model="form.processed_val" step="1"/></td> -->
                <td><datetime date-template="YYYY-MM-dd H:i:s" id="validated-date" v-model="form.processed_val"/></td>
              </tr>
              <tr>
                <td><label for="loaded">DATE SHIPPED:</label></td>
                <!-- <td><b-form-input type="datetime-local" id="loaded" v-model="form.loaded" step="1"/></td> -->
                <td><datetime date-template="YYYY-MM-dd H:i:s" id="loaded" v-model="form.loaded"/></td>
              </tr>
              <tr>
                <td><label for="processed-loaded">PROCESSED SHIPPING:</label></td>
                <!-- <td><b-form-input type="datetime-local" id="processed-loaded" v-model="form.processed_ship" step="1"/></td> -->
                <td><datetime date-template="YYYY-MM-dd H:i:s" id="processed-loaded" v-model="form.processed_ship"/></td>
              </tr>
            </table>
            <input type="submit" class="d-none"/>
          </b-form>
        </b-col>
      </b-row>
    </b-form>
    <template v-slot:modal-footer>
      <div>
        <b-button class="mr-1" variant="danger" v-if="!isNew && archived.length == 0" @click="deleteItem">DELETE</b-button>
        <b-button class="mr-1" variant="success" v-if="!isNew && archived.length > 0" @click="restoreItem">RESTORE</b-button>
        <b-button class="mr-1" variant="secondary" @click="showModal = false">CANCEL</b-button>
        <b-button variant="primary" @click="saveItem">{{ isNew ? 'SAVE' : 'UPDATE' }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import datetime from '@/components/DateTimePicker'
  import { validationStrings } from '@/variables'
  import {numeric, maxLength} from 'vuelidate/lib/validators'

  export default {
    name: 'EditAnimal',
    components: { datetime },
    props: {
      show: {
        type: Boolean,
        required: true
      },
      animalId: Number,
      archived: {
        type: String,
        default: () => ''
      }
    },
    data () {
      return {
        showModal: false,
        form: {
          // animal attributes
          pen: null,
          inducted: null,
          is_validated: null,
          processed_val: null,
          loaded: null,
          processed_ship: null,
          female: null
        },
        ownerOptions: [],
        genderOptions: [
          { value: null, text: 'N/A' },
          { value: true, text: 'Heifer' },
          { value: false, text: 'Steer' }
        ],
        validationOptions: Object.entries(validationStrings).map(([key, value]) => ({ value: key, text: value }))
      }
    },
    /**
     * This is used for validating the form fields
     */
    validations: {
      form: {
        pen: {
          numeric,
          maxLength: maxLength(5)
        }
      }
    },
    mounted () {
      this.fetchOwners()
      // this.fetchMembers()
    },
    watch: {
      show: function (newVal) {
        this.form = {
          pen: null,
          inducted: null,
          is_validated: null,
          processed_val: null,
          loaded: null,
          processed_ship: null,
          female: null
        }
        this.$v.$reset()
        if (newVal) this.fetchData()
        this.showModal = newVal
      }
    },
    computed: {
      invalidFeedback: function () {
        if (this.form.pen > 0 || this.form.pen == "") {
          return ''
        } else {
          return 'This must be a positive number'
        }
      },
      isNew: function () {
        return this.animalId == null
      },
      pageTitle: function () {
        return this.isNew ? 'New Animal' : 'Update Animal'
      }
    },
    methods: {
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? $error ? false : null : null
      },
      /**
       * This fetches the data for the specific animal to be edited.
       */
      fetchData () {
        if (!this.isNew) {
          this.$http.get(`/animals/${this.animalId}/${this.archived}`)
                  .then(response => {
                    console.log('EditAnimal fetchData', response)
                    let animal = response.data
                    this.form = {
                      pen: animal.pen,
                      inducted: animal.inducted,
                      is_validated: animal.is_validated,
                      processed_val: animal.processed_val,
                      loaded: animal.loaded,
                      processed_ship: animal.processed_ship,
                      female: animal.female
                    }
                  })
                  .catch(e => {
                    console.log('EditAnimal fetchData', e, e.response)
                    this.setAlert({ variant: 'danger', message: e.message })
                  })
        }
      },
      fetchOwners () {
        this.$http.get(`/users/?get_all=true`)  // ?usersetting__role=${roles.MEMBER}
                .then(response => {
                  console.log('EditAnimal fetchOwners', response)
                  this.ownerOptions = response.data.map(e => ({ value: e.id, text: `${e.first_name} ${e.last_name}` }))
                })
                .catch(e => {
                  console.log('EditAnimal fetchOwners', e, e.response)
                  this.setAlert({ variant: 'danger', message: e.message })
                })
      },
      /**
       * This checks the form using vuelidate and then edits the animal if the provided information is acceptable.
       */
      saveItem () {
        // console.log(this.form)
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        let request = null
        if (this.isNew) {
          // Post to create
          request = this.$http.post('/animals/', this.form)
        } else {
          // Patch to update
          request = this.$http.patch(`/animals/${this.animalId}/${this.archived}`, this.form)
        }
        request
                .then(response => {
                  console.log(response)
                  this.$emit(this.isNew ? 'create' : 'save', response.data)
                  this.showModal = false
                })
                .catch(e => {
                  console.log(e, e.response)
                  this.setAlert({ variant: 'danger', message: e.message })
                })
      },
      /**
       * This will delete the animal
       */
      deleteItem () {
        this.$http.delete(`/animals/${this.animalId}/`)
          .then(response => {
            console.log(response)
            this.$emit('delete')
            this.showModal = false
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This will restore a deleted animal
       */
      restoreItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        this.form.archived = false
        this.$http.patch(`/animals/${this.animalId}/${this.archived}`, this.form)
          .then(response => {
            console.log('EditAnimal restoreItem', response)
            this.$emit('restore')
            this.showModal = false
          })
          .catch(e => {
            console.log('EditAnimal restoreItem', e, e.response)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  td {
    padding: 5px;
  }
</style>
