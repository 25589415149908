<template>
  <div>
    <Report ref="report"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      :edit-function="editItem"
      new-button-text="NEW MEMBER">
    </Report>
    <EditMember :member-id="selectedId" :show="show" :archived="archived"
      @hide="show = false" @create="onCreate" @save="onSave" @delete="onDelete" @restore="onRestore"></EditMember>
  </div>
</template>

<script>
  import Report from '@/components/Report'
  import EditMember from '@/components/modals/EditMember'

  export default {
    name: 'MemberList',
    props: {
      searchText: String,
      archived: String
    },
    components: { Report, EditMember },
    data () {
      return {
        pageTitle: 'Members',
        model: 'Member',
        fields: [
          {
            key: 'member_id',
            sortable: true
          },
          {
            key: 'business_name',
            label: 'Member name',
            sortable: true
          },
          {
            key: 'street',
            label: 'Address',
            sortable: true
          },
          {
            key: 'postal',
            label: 'Postal code',
            formatter: value => this.postalFormat(value),
            sortable: true
          },
          {
            key: 'city',
            sortable: true
          },
          {
            key: 'province',
            sortable: true
          },
          {
            key: 'phone',
            formatter: value => this.phoneFormat(value),
            sortable: true
          },
          {
            key: 'location_set',
            label: 'Location(s)',
            formatter: value => value.map(e => e.name).join(', '),
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          }
        ],
        show: false,
        selectedId: null,
        selectedIndex: null
      }
    },
    mounted () {
      document.title = `${this.pageTitle} | PBFC`
    },
    watch: {
      archived: function () {
        this.$refs.report.populateTable()
      }
    },
    methods: {
      /**
       * Fetches all Memberorgs according to requestParams
       * @param requestParams arguments to filter memberorgs by
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = `/memberorgs/${this.archived}`
        url += url.endsWith('/') ? '?' : '&'
        url += requestParams.join('&')
        return this.$http.get(url)
          .then(response => {
            console.log('MemberList fetchData', response)
            return response.data
          })
      },
      editItem (data) {
        this.selectedId = data == null ? null : data.item.id
        this.selectedIndex = data == null ? null : data.index
        this.show = true
      },
      onCreate (item) {
        if (!this.archived)
          this.$refs.report.onCreate(item, this.model)
      },
      onSave (item) {
        if (!this.archived)
          this.$refs.report.onSave({item: item, index: this.selectedIndex}, this.model)
      },
      onDelete () {
        if (!this.archived)
          this.$refs.report.onDelete(this.selectedIndex, this.model)
      },
      onRestore () {
        if (this.archived)
          this.$refs.report.onRestore(this.selectedIndex, this.model)
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
