<template>
  <b-modal :title="pageTitle" v-model="showModal" size="lg" @hide="$emit('hide')" scrollable>
    <b-row>
      <b-col>
        <b-form @submit.stop.prevent="saveItem">
          <table class="edit-table">
            <tr>
              <td>
                <label for="funder_name" class="required">LENDING INSTITUTION:</label>
              </td>
              <td>
                <b-form-input
                        id="funder_name"
                        name="funder_name"
                        v-model="$v.form.funder_name.$model"
                        :state="validateState('funder_name')"
                        aria-describedby="funder_name-live-feedback"/>
                <b-form-invalid-feedback
                        id="funder_name-live-feedback"
                >This is a required field and cannot be more than {{$v.form.funder_name.$params.maxLength.max}} letters</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td>
                <label for="contact_name">CONTACT NAME:</label>
              </td>
              <td>
                <b-form-input
                    id="contact_name"
                    name="contact_name"
                    v-model="$v.form.contact_name.$model"
                    :state="validateState('contact_name')"
                    aria-describedby="contact_name-live-feedback"/>
                <b-form-invalid-feedback
                    id="contact_name-live-feedback"
                >This cannot be more than {{$v.form.contact_name.$params.maxLength.max}} letters</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="phone">PHONE:</label></td>
              <td>
                <b-form-input
                    id="phone"
                    name="phone"
                    v-mask="phoneMask"
                    v-model="$v.form.phone.$model"
                    :state="validateState('phone')"
                    aria-describedby="phone-live-feedback"/>
                <b-form-invalid-feedback
                    id="phone-live-feedback"
                >This field cannot be more than {{$v.form.phone.$params.maxLength.max}} digits</b-form-invalid-feedback>
              </td>
            </tr>
            <b-tr>
              <b-td><label for="email">EMAIL:</label></b-td>
              <b-td>
                <b-form-input
                    id="email"
                    name="email"
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="email-live-feedback"/>
                <b-form-invalid-feedback
                    id="email-live-feedback"
                >This must be a valid email address</b-form-invalid-feedback>
              </b-td>
            </b-tr>
            <tr v-if="this.$store.getters.isAdmin">
              <td><label for="member_id" class="required">MEMBER:</label></td>
              <td>
                <b-form-select
                    id="member_id"
                    name="member_id"
                    v-model="$v.form.member_id.$model"
                    :state="validateState('member_id')"
                    :options="memberOptions"/>
              </td>
            </tr>
          </table>
          <input type="submit" class="d-none"/>
        </b-form>
      </b-col>
    </b-row>
    <template v-slot:modal-footer>
      <div>
        <b-button class="mr-1" variant="danger" v-if="!isNew && archived.length == 0" @click="deleteItem">DELETE</b-button>
        <b-button class="mr-1" variant="success" v-if="!isNew && archived.length > 0" @click="restoreItem">RESTORE</b-button>
        <b-button class="mr-1" variant="secondary" @click="showModal = false">CANCEL</b-button>
        <b-button variant="primary" @click="saveItem">{{ isNew ? 'SAVE' : 'UPDATE' }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {required, maxLength, email} from 'vuelidate/lib/validators'
  import { phoneMask } from '@/variables'

  export default {
    name: 'EditFunder',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      funderId: Number,
      archived: {
        type: String,
        default: () => ''
      }
    },
    data () {
      return {
        showModal: false,
        memberOptions: [],
        form: {
          funder_name: null,
          contact_name: null,
          phone: null,
          email: null,
          member_id: null
        },
        phoneMask
      }
    },
    validations: {
      form: {
        funder_name: {
          required,
          maxLength: maxLength(25)
        },
        contact_name: {
          maxLength: maxLength(25)
        },
        phone: {
          maxLength: maxLength(14)
        },
        email: {
          email,
        },
        member_id: {

        }
      }
    },
    watch: {
      show: function (newVal) {
        this.form = {
          funder_name: null,
          contact_name: null,
          phone: null,
          email: null,
          member_id: null,
        }
        this.$v.$reset()
        if (newVal) {
          this.fetchData()
          if (this.$store.getters.isAdmin)
          {
            this.fetchMembers()
          }
        }
        this.showModal = newVal
      }
    },
    computed: {
      isNew: function () {
        return this.funderId == null
      },
      pageTitle: function () {
        return this.isNew ? 'New Lending Institution' : 'Update Lending Institution'
      },
    },
    methods: {
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? $error ? false : null : null
      },
      fetchData () {
        if (!this.isNew) {
          this.$http.get(`/funders/${this.funderId}/${this.archived}`)
            .then(response => {
              console.log('EditFunder fetchData', response)
              Object.keys(this.form).forEach(k => this.form[k] = response.data[k])
              this.form.member_id = response.data.member.id
            })
            .catch(e => {
              console.log('EditFunder fetchData', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
        }
      },
      fetchMembers () {
        this.$http.get(`/memberorgs/?get_all=true`)
            .then(response => {
              console.log('EditFunder fetchMembers', response)
              this.memberOptions = response.data.map(e => ({ value: e.id, text: `${e.business_name} (${e.member_id})` }))
            })
            .catch(e => {
              console.log('EditFunder fetchMembers', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
      },
      saveItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        if (this.$store.getters.isMember)
        {
          this.form.member_id = this.$store.getters.user.usersetting.memberorg
        }
        console.log(this.form)
        this.form = this.nullBlanks(this.form)
        console.log(this.form)
        let request = null
        if (this.isNew) {
          request = this.$http.post('/funders/', this.form)
        } else {
          request = this.$http.patch(`/funders/${this.funderId}/${this.archived}`, this.form)
        }
        request
          .then(response => {
            console.log('EditFunder saveItem', response)
            this.$emit(this.isNew ? 'create' : 'save', response.data)
            this.showModal = false
          })
          .catch(e => {
            console.log('EditFunder saveItem', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      deleteItem () {
        this.$http.delete(`/funders/${this.funderId}/`)
          .then(response => {
            console.log(response)
            this.$emit('delete', this.funderId)
            this.showModal = false
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      restoreItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        this.form.archived = false
        this.$http.patch(`/funders/${this.funderId}/${this.archived}`, this.form)
          .then(response => {
            console.log('EditFunder restoreItem', response)
            this.$emit('restore')
            this.showModal = false
          })
          .catch(e => {
            console.log('EditFunder restoreItem', e, e.response)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
td {
  padding: 5px;
}
</style>
