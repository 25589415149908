<template>
  <b-modal :title="pageTitle" v-model="showModal" size="lg" @hide="$emit('hide')" scrollable>
    <b-row>
      <b-col>
        <b-form @submit.stop.prevent="saveItem">
          <table class="edit-table">
            <tr>
              <td>
                <label for="member_id" class="required">MEMBER ID:</label>
              </td>
              <td>
                <b-form-input
                        id="member_id"
                        name="member_id"
                        maxlength="10"
                        v-mask="'*{0,10}'"
                        v-model="$v.form.member_id.$model"
                        :state="validateState('member_id')"
                        aria-describedby="member_id-live-feedback"/>
                <b-form-invalid-feedback
                        id="member_id-live-feedback"
                >This is a required field and cannot be more than {{$v.form.member_id.$params.maxLength.max}} digits</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td>
                <label for="business_name" class="required">MEMBER NAME:</label>
              </td>
              <td>
                <b-form-input
                        id="business_name"
                        name="business_name"
                        v-model="$v.form.business_name.$model"
                        :state="validateState('business_name')"
                        aria-describedby="business_name-live-feedback"/>
                <b-form-invalid-feedback
                        id="business_name-live-feedback"
                >This is a required field and cannot be more than {{$v.form.business_name.$params.maxLength.max}} letters</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="street">ADDRESS:</label></td>
              <td>
                <b-form-input
                        id="street"
                        name="street"
                        v-model="$v.form.street.$model"
                        :state="validateState('street')"
                        aria-describedby="street-live-feedback"/>
                <b-form-invalid-feedback
                        id="street-live-feedback"
                >This field cannot be more than {{$v.form.street.$params.maxLength.max}} letters</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="city">CITY:</label></td>
              <td>
                <b-form-input
                        id="city"
                        name="city"
                        v-model="$v.form.city.$model"
                        :state="validateState('city')"
                        aria-describedby="city-live-feedback"/>
                <b-form-invalid-feedback
                        id="city-live-feedback"
                >This field cannot be more than {{$v.form.city.$params.maxLength.max}} letters</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="province" class="required">PROVINCE:</label></td>
              <td>
                <b-form-select
                        id="province"
                        name="province"
                        v-model="form.province"
                        :options="provinceOptions"
                        aria-describedby="province-live-feedback">
                  </b-form-select>
                <b-form-invalid-feedback id="province-live-feedback">Please select a province</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="postal">POSTAL CODE:</label></td>
              <td>
                <b-form-input
                        id="postal"
                        name="postal"
                        class="text-uppercase"
                        v-mask="postalMask"
                        v-model="$v.form.postal.$model"
                        :state="validateState('postal')"
                        aria-describedby="postal-live-feedback"/>
                <b-form-invalid-feedback
                        id="postal-live-feedback"
                >This field cannot be more than {{$v.form.postal.$params.maxLength.max}} characters</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="phone">PHONE:</label></td>
              <td>
                <b-form-input
                        id="phone"
                        name="phone"
                        v-mask="phoneMask"
                        v-model="$v.form.phone.$model"
                        :state="validateState('phone')"
                        aria-describedby="phone-live-feedback"/>
                <b-form-invalid-feedback
                        id="phone-live-feedback"
                >This field cannot be more than {{$v.form.phone.$params.maxLength.max}} digits</b-form-invalid-feedback>
              </td>
            </tr>
          </table>
          <input type="submit" class="d-none"/>
        </b-form>
      </b-col>
    </b-row>
    <template v-slot:modal-footer>
      <div>
        <b-button class="mr-1" variant="danger" v-if="!isNew && archived.length == 0 && $store.getters.isSupervisor" @click="deleteItem">DELETE</b-button>
        <b-button class="mr-1" variant="success" v-if="!isNew && archived.length > 0" @click="restoreItem">RESTORE</b-button>
        <b-button class="mr-1" variant="secondary" @click="showModal = false">CANCEL</b-button>
        <b-button variant="primary" @click="saveItem">{{ isNew ? 'SAVE' : 'UPDATE' }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import {required, maxLength, alphaNum} from 'vuelidate/lib/validators'
  import { phoneMask, postalMask, provinceCodes } from '@/variables'

  export default {
    name: 'EditMember',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      memberId: Number,
      archived: {
        type: String,
        default: () => ''
      }
    },
    data () {
      return {
        showModal: false,
        form: {
          member_id: null,
          business_name: null,
          street: null,
          city: null,
          province: null,
          postal: null,
          phone: null,
        },
        postalMask, phoneMask
      }
    },
    /**
     * This is used for validating the form fields
     */
    validations: {
      form: {
        member_id: {
          required,
          alphaNum,
          maxLength: maxLength(10)
        },
        business_name: {
          required,
          maxLength: maxLength(25)
        },
        street: {
          maxLength: maxLength(30)
        },
        city: {
          maxLength: maxLength(25)
        },
        postal: {
          maxLength: maxLength(7),
          function (value) {
            if (value == null || value == '') return true
            return this.postalFilter(value) != null
          }
        },
        phone: {
          maxLength: maxLength(14)
        }
      }
    },
    watch: {
      show: function (newVal) {
        this.form = {
          member_id: null,
          business_name: null,
          business_short: null,
          street: null,
          city: null,
          province: null,
          postal: null,
          phone: null,
        }
        this.$v.$reset()
        if (newVal) this.fetchData()
        this.showModal = newVal
      }
    },
    computed: {
      isNew: function () {
        return this.memberId == null
      },
      pageTitle: function () {
        return this.isNew ? 'New Member' : 'Update Member'
      },
      provinceOptions () {
        let opts = Object.entries(provinceCodes).map(([k, v]) => ({ value: k, text: v }))
        opts.unshift({ value: null, text: 'Please select a province' })
        return opts
      }
    },
    methods: {
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? $error ? false : null : null
      },
      /**
       * This fetches the data for the specific memberorg to be edited.
       */
      fetchData () {
        if (!this.isNew) {
          this.$http.get(`/memberorgs/${this.memberId}/${this.archived}`)
            .then(response => {
              console.log('EditMember fetchData', response)
              Object.keys(this.form).forEach(k => this.form[k] = response.data[k])
            })
            .catch(e => {
              console.log('EditMember fetchData', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
        }
      },
      /**
       * This checks the form using vuelidate and then edits the memberorg if the provided information is acceptable.
       */
      saveItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        // in case of autofill entering different format which bypasses inputmask raw value
        this.form.postal = this.postalFilter(this.form.postal)
        let request = null
        if (this.isNew) {
          request = this.$http.post('/memberorgs/', this.form)
        } else {
          request = this.$http.patch(`/memberorgs/${this.memberId}/${this.archived}`, this.form)
        }
        request
          .then(response => {
            console.log('EditMember saveItem', response)
            this.$emit(this.isNew ? 'create' : 'save', response.data)
            this.showModal = false
          })
          .catch(e => {
            console.log('EditMember saveItem', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This will delete the memberorg
       */
      deleteItem () {
        this.$http.delete(`/memberorgs/${this.memberId}/`)
          .then(response => {
            console.log(response)
            this.$emit('delete', this.memberId)
            this.showModal = false
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This will restore a deleted memberorg
       */
      restoreItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        this.form.archived = false
        this.$http.patch(`/memberorgs/${this.memberId}/${this.archived}`, this.form)
          .then(response => {
            console.log('EditMember restoreItem', response)
            this.$emit('restore')
            this.showModal = false
          })
          .catch(e => {
            console.log('EditMember restoreItem', e, e.response)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
td {
  padding: 5px;
}
</style>
