import { render, staticRenderFns } from "./EditTag.vue?vue&type=template&id=74c80ade&scoped=true&"
import script from "./EditTag.vue?vue&type=script&lang=js&"
export * from "./EditTag.vue?vue&type=script&lang=js&"
import style0 from "./EditTag.vue?vue&type=style&index=0&id=74c80ade&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74c80ade",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BFormInvalidFeedback, BFormCheckbox, BFormTextarea, BForm, BCol, BRow, BButton, BModal} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BFormInvalidFeedback, BFormCheckbox, BFormTextarea, BForm, BCol, BRow, BButton, BModal})
    

export default component.exports