<template>
  <b-container fluid class="page-content">
    <b-row>
      <b-breadcrumb></b-breadcrumb>
    </b-row>
    <b-row>
      <div class="page-title">
        App Settings
      </div>
    </b-row>
    <b-form @submit.prevent="saveData" class="d-flex flex-grow-1">
      <b-row class="flex-grow-1 d-flex">
        <b-col md="8" lg="6" class="d-flex flex-column">
          <b-row>
            <b-col>
              <b-form-group label="Shipped email notification check interval" label-for="check-loading-input"
                            aria-describedby="check-loading-help" description="Check for shipped animals every number of minutes to send email notifications">
                <b-input-group append="minutes">
                  <b-form-input id="check-loading-input" v-model="settings.loading_interval" type="number" append="minutes"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Heartbeat email notification check interval" label-for="check-heartbeat-input"
                            aria-describedby="check-heartbeat-help" description="Check for missed gate heartbeats every number of minutes to send email notifications">
                <b-input-group append="minutes">
                  <b-form-input id="check-heartbeat-input" v-model="settings.heartbeat_interval" type="number"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-v="end" class="mt-3">
            <b-col cols="auto">
              <b-button class="mr-1" variant="primary" type="submit" :disabled="isLoading">Save</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
  export default {
    name: 'AppSettings',
    data () {
      return {
        settings: {
          id: null,
          loading_interval: null,
          heartbeat_interval: null
        },
        isLoading: false
      }
    },
    mounted () {
      this.fetchData()
    },
    methods: {
      /**
       * This fetches all appsettings
       */
      fetchData () {
        this.$http.get('/appsettings/')
          .then(response => {
            console.log('AppSettings fetchData', response)
            this.settings = response.data
          })
          .catch(e => {
            console.log('AppSettings fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: 'Failed to retrieve server settings' })
          })
      },
      /**
       * This is used to update appsettings
       */
      saveData () {
        this.isLoading = true
        this.$http.patch(`/appsettings/${this.settings.id}/`, this.settings)
          .then(response => {
            console.log('AppSettings saveData', response)
            this.setAlert({ variant: 'success', message: 'Server settings updated' })
            this.settings = response.data
            this.isLoading = false
          })
          .catch(e => {
            console.log('AppSettings saveData', e, e.response)
            this.setAlert({ variant: 'danger', message: 'Failed to update server settings' })
            this.isLoading = false
          })
      }
    }
  }
</script>
